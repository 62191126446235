<template>
    <div>
        <el-page-header @back="$router.go(-1)" content="邮件记录"/>
        <div style="margin-top: 20px">
            <div style="text-align: center">
                <el-form :inline="true" :model="search" class="demo-form-inline">
                    <el-form-item label="证书">
                        <el-select v-model="search.cid" placeholder="请选择证书">
                            <el-option :label="item.title" :value="item.id" v-for="(item, idx) in certs" :key="idx"></el-option>
                        </el-select>
                    </el-form-item>
                    <el-form-item label="姓名">
                        <el-input v-model="search.name" placeholder="姓名"></el-input>
                    </el-form-item>
                    <el-form-item label="身份证号">
                        <el-input v-model="search.idnum" placeholder="身份证号"></el-input>
                    </el-form-item>
                    <el-form-item label="邮箱地址">
                        <el-input v-model="search.email" placeholder="邮箱地址"></el-input>
                    </el-form-item>
                    <el-form-item>
                        <el-button type="primary" @click="getData">查询</el-button>
                    </el-form-item>
                </el-form>
            </div>
            <el-table stripe :data="table.data" style="width: 100%;margin-top:10px" v-loading="loading" border>
                <el-table-column type="index" width="50" align="center">
                    <template slot-scope="scope">
                        <div v-text="scope.$index + 1 + ((table.page -1) * table.pageSize)"></div>
                    </template>
                </el-table-column>
                <el-table-column align="center"
                                 v-for="(v, idx) in table.columns"
                                 :prop="v.field"
                                 :label="v.title"
                                 :width="v.width"
                                 :key="idx">
                </el-table-column>
            </el-table>
            <div style="text-align: center;margin: 20px">
                <el-pagination layout="total, prev, pager, next, sizes" :page-sizes="[5, 10, 20, 30, 50, 100]"
                               :total="table.total" :page-size="table.pageSize" @current-change="handleCurrentChange"
                               @size-change="handleSizeChange"/>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        data() {
            return {
                loading: false,
                certs: [],
                search: {
                    cid: null,
                    name: '',
                    idnum: '',
                    email:'',
                },
                table: {
                    columns: [
                        {title: '时间', field: 'createTime', width: '200'},
                        {title: '姓名', field: 'name', width: '200'},
                        {title: '身份证号', field: 'idnum', width: '200'},
                        {title: '邮箱地址', field: 'email', width: '200'},
                        {title: '记录类型', field: 'title', width: '200'},
                        {title: '记录详情', field: 'content', width: ''},
                    ],
                    data: [],
                    total: 0,
                    page: 1,
                    pageSize: 20
                },
            }
        },
        methods: {
            handleSizeChange(e){
                this.table.pageSize = e
                this.table.page = 1
                this.getData()
            },
            handleCurrentChange(e){
                this.table.page = e
                this.getData()
            },
            async getData() {
                const cert = await this.$http.get('/cert/?page=1&pageSize=1000')
                this.certs = cert.data.data.content
                this.search.page = this.table.page
                this.search.pageSize = this.table.pageSize
                if (this.$route.query.cid) {
                    this.search.cid = parseInt(this.$route.query.cid)
                }
                const resp = await this.$http.get(`/maillog/`, {params: this.search})
                this.table.data = resp.data.data.content
                this.table.total = resp.data.data.totalElements
            }
        },
        mounted(){
            this.getData()
        }
    }
</script>

<style scoped>
</style>
